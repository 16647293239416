export default {
  'APP_SHIPMENTS_VIEW': '',
  'APP_SHIPMENT_DETAIL_VIEW': '',
  'APP_PURCHASEORDERS_VIEW': '',
  'APP_PURCHASEORDER_DETAIL_VIEW': '',
  'APP_RETURNS_VIEW': '',
  'APP_RETURN_DETAIL_VIEW': '',
  'APP_SHIPMENT_UPDATE': 'RECEIVING_ADMIN',
  'APP_SHIPMENT_ADMIN': 'RECEIVING_ADMIN',
  'APP_RECVG_ADMIN': 'COMMON_ADMIN',
  'APP_PRODUCT_IDENTIFIER_UPDATE':'COMMON_ADMIN',
  'RECEIVING_APP_VIEW': 'RECEIVING_APP_VIEW',
  'APP_COMMERCE_VIEW': 'COMMERCEUSER_VIEW',
  'APP_UPDT_FULFILL_FORCE_SCAN_CONFIG': 'COMMON_ADMIN'
} as any
